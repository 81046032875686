@import "bootstrap";

body {
  overflow-x: hidden;

  font-family: "Roboto", sans-serif;
  color: $gray-900;
}

img {
  pointer-events: none;
}

canvas {
  height: 100%;
  width: 100%;
}

section {
  overflow: hidden;
}

.container-inner-spacing {
  padding-block: $spacer-8;

  @include media-breakpoint-up(md) {
    padding-block: $spacer-10;
  }
}

.container-outer-spacing {
  margin-block: $spacer-7;

  @include media-breakpoint-up(md) {
    margin-block: $spacer-6;
  }
}

.fw-semi-bold {
  font-weight: 500;
}

.max-content-width {
  @include media-breakpoint-up(xxl) {
    max-width: 1040px;
  }
}

