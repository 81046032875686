@import "../../../styles/modules";

.cookies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $dark;
  position: fixed;
  z-index: 120;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  box-shadow: 6px 0px 8px rgba(0, 0, 0, 0.25), -6px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: $border-radius-lg $border-radius-lg 0px 0px;

  @include media-breakpoint-up(md) {
    border-radius: $border-radius-lg $border-radius-lg 0px 0px;
    position: fixed;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 684px;
    padding: 2rem;
    font-size: 1rem;
  }
}

.text {
  color: $white;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    max-width: 400px;
    text-align: left;
  }
}

.button {
  width: 200px;
  font-size: 0.875rem;
  margin-top: $spacer-3;
  margin-inline: auto;

  @include media-breakpoint-up(md) {
    width: 100px;
    margin-top: 0;
  }
}
